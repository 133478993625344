@tailwind base;
@tailwind components;
@tailwind utilities;

/* Imports for Sanity */
.cursive {
  font-family: "Amatic SC", cursive;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.brand-green {
  color: rgba(6, 95, 70, var(25%));
}
